import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Hero from '../../components/heroSubpage'
import ColumnAlternatingButton from '../../components/columnAlternatingButton'
import ColumnAlternatingWistia from '../../components/columnAlternatingWistia'
import Card from '../../components/card'
import RecommendedContent from "../../components/recommendedContent"
import Cta from '../../components/cta'
import {targetingEnabled} from '../../utils/helpers.js'

export default ({ data, location }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="sub-page">
        <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname} 
          image={data.site.siteMetadata.siteUrl + post.frontmatter.image.childImageSharp.fluid.src}          
        />

        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.fluid}
          foreimage={null}
          imageAltText={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}           
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                <div
                    className="font-w-400 text-xs-extra-large divlink text-opening-para"
                    dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                  />
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </MDBCol>               
              </MDBRow>
            </MDBContainer>
          </MDBAnimation>
        </section>

        <RecommendedContent />        

        {post.frontmatter.podcast && 
          <section className={`${targetingEnabled() ? 'bg-white' : 'bg-white-grey-gradient'}`}>
            <MDBAnimation type="fadeIn" duration="1587ms" count={1}>              
              <MDBContainer>
                {post.frontmatter.podcast.link.indexOf("wistia") > 0 ? 
                  <ColumnAlternatingWistia
                    textCol={'5'}
                    imageCol={'7'}
                    placement={post.frontmatter.podcast.placement}
                    title={post.frontmatter.podcast.title}
                    subtitle={post.frontmatter.podcast.subtitle}
                    description={post.frontmatter.podcast.description}
                    titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                    link={post.frontmatter.podcast.link}
                    height={post.frontmatter.podcast.height}
                    ctalink={post.frontmatter.podcast.ctalink}
                    ctatext={post.frontmatter.podcast.ctatext}
                    icon={post.frontmatter.podcast.icon}
                  />
                :
                  <ColumnAlternatingButton
                    textCol={'5'}
                    imageCol={'7'}
                    placement={post.frontmatter.podcast.placement}
                    title={post.frontmatter.podcast.title}
                    subtitle={post.frontmatter.podcast.subtitle}
                    description={post.frontmatter.podcast.description}
                    imageUrl={post.frontmatter.podcast.image.childImageSharp.fluid}
                    imageAltText={post.frontmatter.podcast.alttext}
                    imageClass="rounded drop-shadow"
                    titleClass="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium"
                    link={post.frontmatter.podcast.link}
                    linktext={post.frontmatter.podcast.linktext}
                    ctalink={post.frontmatter.podcast.ctalink}
                    ctatext={post.frontmatter.podcast.ctatext}
                    icon={post.frontmatter.podcast.icon}
                  />
                }
              </MDBContainer>
            </MDBAnimation>
          </section>
        }

        {post.frontmatter.storagesols.map((sections, index) => (
          <Fragment key={index}>
            <section className="bg-white-grey-gradient">
              <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
                <MDBContainer>
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-medium title-medium">
                    {sections.title}
                  </h2>
                  <p className="font-w-400 mb-4 text-medium">
                    {sections.subtitle}
                  </p>
                  <MDBRow className="pt-4">
                    {sections.platformstoragesols.map((storages, index) => (
                      <Fragment key={index}>
                        <Card
                          id={index}
                          collg="4"
                          colmd="6"
                          height="10.5rem"
                          title={storages.title}
                          subtitle={storages.subtitle}
                          description={storages.description}
                          imageUrl={storages.image.childImageSharp.fluid}
                          imageAltText={storages.alttext}
                          placement={storages.placement}
                          link={storages.link}
                        />
                      </Fragment>
                    ))}
                  </MDBRow>
                </MDBContainer>
              </MDBAnimation>
            </section>
          </Fragment>
        ))}

        <section className="bg-blue-green-gradient">
          <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
            <MDBContainer>
              <Cta
                ctatitle={post.frontmatter.cta.title}
                ctadescription={post.frontmatter.cta.description}
                ctalink={post.frontmatter.cta.link}
                ctalinktext={post.frontmatter.cta.linktext}
              />
            </MDBContainer>
          </MDBAnimation>
        </section>
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }     
    markdownRemark(frontmatter: { name: { eq: "storage" } }) {
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        alttext
        ctatext
        ctaslug
        openingpara
        podcast {
          placement
          title
          subtitle
          description
          linktext
          link
          height
          ctatext
          ctalink
          icon
          alttext
          image {
            childImageSharp {
              fluid(maxWidth: 900, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }        
        storagesols {
          title
          subtitle
          platformstoragesols {
            title
            subtitle
            description
            image {
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            placement
            alttext
            link
          }
        }
        cta {
          title
          description
          linktext
          link
        }
      }
      html
    }
  }
`